import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Portal from '@reach/portal';
import { Tween } from 'react-gsap';
import { gsap } from 'gsap';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollToPlugin } from 'gsap/all';
// import scrollTo from 'gatsby-plugin-smoothscroll';
import { useIntl } from 'gatsby-plugin-intl';
// import useWindowSize from 'react-use/lib/useWindowSize';
// import Confetti from 'react-confetti';
// import Image from 'gatsby-image';
import { useMedia } from 'use-media';
// import { isMobileSafari } from 'react-device-detect';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
// import SelectLang from '../../components/selectLang';
import ClientOnly from '../../components/clientOnly';
import Cursor from '../../components/cursor';
// import Video from '../../components/video';
import { Section, Div, Span, H1, H3, P, Button, A } from '../../components/styles';
// import Header from '../../components/header';
// import iphoneLeftBottom from '../../images/phones/phone_left_bottom.png';
// import iphoneMiddle from '../../images/phones/phone_middle.png';
// import iphoneLeftTop from '../../images/phones/phone_left_top.png';
// import iphoneRightTop from '../../images/phones/phone_right_top.png';
// import iphoneRightBottom from '../../images/phones/phone_right_bottom.png';
// import video1 from '../../../static/video/video1.mp4';
// import video2 from '../../../static/video/video2.mp4';
// import video3 from '../../../static/video/video3.mp4';
// import video4 from '../../../static/video/video4.mp4';
import video5 from '../../../static/video/video5.mp4';
import video5thumb from '../../../static/video/video5thumb.png';
// import b from "../images/videochars/b.png"

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, MotionPathPlugin);

const forContentCreatorsKeywords = [
  { id: '1' },
  { id: '2' },
  { id: '3' },
  { id: '4' },
  { id: '5' },
  { id: '6' },
  { id: '7' },
  { id: '8' },
  { id: '9' },
  { id: '10' },
  { id: '11' },
  { id: '12' },
  { id: '13' },
  { id: '14' },
  { id: '15' },
  { id: '16' },
  { id: '17' },
  { id: '18' },
  { id: '19' },
  { id: '21' },
  { id: '22' },
  { id: '23' },
  { id: '24' },
  { id: '25' },
  { id: '26' },
  { id: '27' },
  { id: '28' },
  { id: '29' },
  { id: '30' },
  { id: '31' },
];

const IndexPage = ({ data }) => {
  const [isLoading, setLoading] = useState(true);
  const [isActiveModal, setActiveModal] = useState(false);
  // const [isTermModalActive, setOpenTerms] = useState(false);
  // const [isConfettiActive, runConfetti] = useState(false);
  // const [isPolicyModalActive, setOpenPolicy] = useState(false);
  const [subMessage, setSubscribeMessage] = useState(undefined);

  const [email, setEmail] = useState(false);
  const Intl = useIntl();

  const isTablet = useMedia({ minWidth: '768px' });

  // const introHeadingRef = useRef();

  // const { width, height } = useWindowSize();

  useEffect(() => {
    if (isLoading) {
      document.querySelector('body').classList.add('prevent-scroll');
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      const { body } = document;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;
    } else {
      const { body } = document;
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      document.querySelector('body').classList.remove('prevent-scroll');
    }
  }, [isLoading]);

  useEffect(() => {
    const tl = gsap.timeline();

    const h1Tl = tl
      .to('.svg-mask', {
        scale: 10,
        autoAlpha: 0,
      })
      .fromTo(
        '.video-scroll-text-1',
        {
          autoAlpha: 0,
          yPercent: 100,
          scale: 0.5,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          scale: 1,
        }
      )
      .to('.video-scroll-text-1', {
        scale: 0.5,
        autoAlpha: 0,
        yPercent: -100,
      })
      .fromTo(
        '.video-scroll-text-2',
        {
          autoAlpha: 0,
          yPercent: 100,
          scale: 0.5,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          scale: 1,
        }
      )
      .to('.video-scroll-text-2', {
        scale: 0.5,
        autoAlpha: 0,
        yPercent: -100,
      })
      .fromTo(
        '.video-scroll-text-3',
        {
          autoAlpha: 0,
          yPercent: 100,
          scale: 0.5,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          scale: 1,
        }
      )
      .to('.video-scroll-text-3', {
        scale: 0.5,
        autoAlpha: 0,
        yPercent: -100,
      });

    ScrollTrigger.create({
      trigger: '.video-scroll-text-1',
      animation: h1Tl,
      scrub: 0.5,
      start: 'center center',
      end: '+=2800px',
    });
  }, []);

  return (
    <Layout>
      <SEO
        title={Intl.formatMessage({ id: 'title' })}
        desc={Intl.formatMessage({ id: 'description' })}
        keywords={Intl.formatMessage({ id: 'keywords' })}
      />
      <main>
        <ClientOnly>
          {isTablet && !isLoading && (
            <Cursor
              color="0, 255, 36"
              outerAlpha={1}
              innerSize={12}
              outerSize={31}
              outerScale={2}
            />
          )}
        </ClientOnly>
        <Section id="stay-tuned" bg="var(--section-bg-color)" minHeight="100vh" data-section={4}>
          <Div
            className="trigger-stay-tuned"
            display="grid"
            gridTemplateColumns={['1fr']}
            alignItems="center"
            maxWidth="1440px"
            minHeight={['100vh', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Div>
              <AnimateSharedLayout type="crossfade">
                <Button
                  layoutId="modal"
                  width={['265px', '265px', '331px']}
                  height={['66px', '66px', '82px']}
                  mx={['auto', 'auto', 'initial']}
                  border="none"
                  my="5rem"
                  style={{
                    display: 'block',
                    backgroundImage: 'linear-gradient(122deg, #45E0B7 13%, #FFF7AF 100%)',
                    borderRadius: '4rem',
                    fontSize: '2.3rem',
                    color: '#000',
                    textAlign: 'center',
                    border: 'none',
                    fontWeight: 700,
                  }}
                  onClick={() => {
                    setActiveModal(!isActiveModal);
                  }}
                >
                  <motion.span layoutId="text" style={{ display: 'inline-block' }}>
                    {Intl.formatMessage({
                      id: 'stayTuned.subscribe.text',
                    })}
                  </motion.span>
                </Button>
                <Portal>
                  <AnimatePresence>
                    {isActiveModal && (
                      <Div
                        layoutId="modal"
                        className="modal"
                        position="fixed"
                        top={0}
                        right={0}
                        bottom={0}
                        left={0}
                        width="100%"
                        height="100%"
                        zIndex={20}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <H1
                          layoutId="text"
                          position="absolute"
                          top={['40px']}
                          left="8.75%"
                          fontSize={['4rem', '4rem', '5rem']}
                          fontWeight="bold"
                          color="#fff"
                          opacity={0.7}
                        >
                          {Intl.formatMessage({
                            id: 'stayTuned.subscribe.text',
                          })}
                        </H1>

                        <Button
                          bg="transparent"
                          border="none"
                          position="absolute"
                          top={['20px', '20px', '40px']}
                          right="8.75%"
                          width={['45px', '45px', '75px']}
                          height={['45px', '45px', '75px']}
                          p="0"
                          m="0"
                          onClick={() => setActiveModal(false)}
                        >
                          <svg
                            width="100%"
                            viewBox="0 0 75 75"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              display: 'block',
                              opacity: 0.6,
                            }}
                          >
                            <g fill="#fff" fillRule="nonzero">
                              <path d="M37.45 0c20.7 0 37.448 16.748 37.448 37.45 0 20.7-16.748 37.448-37.449 37.448C16.75 74.898 0 58.15 0 37.45 0 16.75 16.748 0 37.45 0zm0 4.8C19.4 4.8 4.8 19.4 4.8 37.45c0 18.05 14.6 32.648 32.65 32.648 18.05 0 32.648-14.599 32.648-32.649S55.5 4.8 37.45 4.8z" />
                              <path d="M50.976 25.024c.728.727.76 1.886.1 2.652l-.1.107L40.76 38l10.216 10.218a1.951 1.951 0 01-2.652 2.858l-.107-.099L38 40.76 27.783 50.976a1.951 1.951 0 01-2.858-2.652l.099-.107L35.24 37.999 25.024 27.783a1.951 1.951 0 012.652-2.858l.107.099L38 35.239l10.217-10.215a1.951 1.951 0 012.76 0z" />
                            </g>
                          </svg>
                        </Button>
                        <form
                          onSubmit={async (evt) => {
                            evt.preventDefault();
                            const resp = await fetch(
                              `https://jd5ekuaoi9.execute-api.eu-central-1.amazonaws.com/prod/subscribe`,
                              {
                                method: 'POST',
                                headers: {
                                  'Content-Type': 'application/json;domain-model=subscribeEmail',
                                },
                                body: JSON.stringify({ email }),
                              }
                            );

                            const result = await resp.json();

                            if (result.message) {
                              setSubscribeMessage({ result });
                            }
                          }}
                        >
                          <Div display="flex" flexDirection="column" alignItems="center" mx="8.75%">
                            <input
                              className="input"
                              type="text"
                              name="email"
                              placeholder={Intl.formatMessage({
                                id: 'stayTuned.subscribe.input',
                              })}
                              onChange={(evt) => setEmail(evt.target.value)}
                            />
                            {subMessage && subMessage.result && (
                              <Span color={subMessage.result.code ? 'red' : '#fff'} mt="5px">
                                {subMessage.result.message}
                              </Span>
                            )}
                            <Button
                              fontWeight={['bold']}
                              fontSize={['1.8rem', '1.8rem', '2.3rem']}
                              bg="black"
                              color="#fff"
                              width={['133px', '133px', '190px']}
                              height={['60px', '60px', '80px']}
                              border="none"
                              borderRadius={['30px', '30px', '40px']}
                              marginTop={['40px', '40px', '50px']}
                              type="submit"
                            >
                              {Intl.formatMessage({
                                id: 'stayTuned.subscribe.button',
                              })}
                            </Button>
                          </Div>
                        </form>
                      </Div>
                    )}
                  </AnimatePresence>
                </Portal>
              </AnimateSharedLayout>
            </Div>
          </Div>
          <Div
            display="grid"
            alignItems="center"
            maxWidth="1440px"
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Div mt="6rem" mb="3rem" textAlign={['center', 'center', 'left']}>
              <Div
                display="grid"
                alignSelf="start"
                justifyContent="start"
                initial={{ y: 100 }}
                animate={{ y: 0 }}
                exit={{ y: 100 }}
              >
                <Div display="flex" flexDirection="column" alignItems="center">
                  <P fontSize={['1.4rem', '1.4rem', '2rem']} color="var(--section-text-color)">
                    {Intl.formatMessage({ id: 'stayTuned.section1.text4' })}
                  </P>

                  <svg
                    className="arrow-down"
                    viewBox="0 0 20 27"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 23.088l-6.8-6.831c-.4-.4-1-.4-1.4 0-.198.2-.3.502-.3.702 0 .2.102.503.3.703L9.303 26.2c.398.4 1 .4 1.398 0l8.501-8.538a.975.975 0 000-1.405c-.398-.4-1-.4-1.398 0L11 23.088V1.504c0-.604-.398-1.004-1-1.004-.598 0-1 .4-1 1.004v21.584z"
                      fill="var(--section-text-color)"
                      fillRule="nonzero"
                    />
                  </svg>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            className="trigger-white"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1440px"
            minHeight={['100vh', '100vh', '100vh', '100vh', '680px']}
            mx="auto"
            px={['0', '0', '0', '8rem']}
          >
            <Div>
              <Tween
                to={{
                  scrollTrigger: {
                    trigger: '.trigger-white',
                    start: `top +=65%`,
                    onLeaveBack() {
                      gsap.set('html', {
                        '--header-background': '#000',
                        '--primary-link-color': '#fff',
                        '--logo-color': '#fff',
                        '--section-bg-color': '#000',
                        '--section-text-color': '#fff',
                      });
                    },
                    onEnter({ isActive }) {
                      if (isActive) {
                        gsap.set('html', {
                          '--header-background': '#fff',
                          '--primary-link-color': '#000',
                          '--logo-color': '#000',
                          '--section-bg-color': '#fff',
                          '--section-text-color': '#000',
                        });
                      }
                    },
                  },
                }}
              >
                <video
                  controls
                  poster={video5thumb}
                  preload="metadata"
                  muted={false}
                  autoPlay={false}
                  playsInline
                  src={video5}
                />
              </Tween>
            </Div>
          </Div>

          <Div
            className="trigger-see-soon"
            position="relative"
            display="grid"
            alignItems="center"
            justifyContent="center"
            py="60px"
            maxWidth="1440px"
            minHeight={['100vh', '100vh', '100vh', '100vh', '100vh']}
            mx="auto"
            px={['8.75%', '8.75%', '8.75%', '8rem']}
          >
            <Div>
              <Tween
                from={{
                  autoAlpha: 0,
                  y: 100,
                }}
                to={{
                  scrollTrigger: {
                    trigger: '.trigger-see-soon',
                    start: 'top +=30%',
                  },
                  autoAlpha: 1,
                  y: 0,
                }}
              >
                <H3 fontSize={['3.4rem', '5rem']} lineHeight={['4rem', '6rem']} m={0}>
                  {Intl.formatMessage({ id: 'stayTuned.section3.heading1' })}
                </H3>
              </Tween>
            </Div>
            <Div position="absolute" bottom={0} left={0} width="100%" py="35px" textAlign="center">
              <P
                color="var(--section-text-color)"
                fontSize={['1.1rem', '1.2rem']}
                lineHeight={['1.3rem', '1.4rem']}
                fontWeight={500}
              >
                {Intl.formatMessage({ id: 'copyright.Copyright' })}
              </P>
              <A
                href="#terms"
                color="var(--section-text-color)"
                onClick={(evt) => {
                  evt.preventDefault();
                  setOpenPolicy(false);
                  setOpenTerms(true);
                }}
              >
                {Intl.formatMessage({
                  id: 'termsofservice.TermsOfService',
                })}
              </A>{' '}
              <A
                href="#privacy"
                color="var(--section-text-color)"
                onClick={(evt) => {
                  evt.preventDefault();
                  setOpenTerms(false);
                  setOpenPolicy(true);
                }}
              >
                {Intl.formatMessage({ id: 'dataprivacy.DataPrivacyStatement' })}
              </A>
            </Div>
          </Div>
        </Section>
      </main>
    </Layout>
  );
};

export default IndexPage;
